<template>
	<div>
		<div class="header filter">
			<!-- 해더 -->
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="$emit('goBack')"
						class="arrow_white"
					>
						<i class="icon-arrow-left"></i>
					</button>
				</div>
				<div class="page_tit_area">
					<h2 class="page_tit">{{  program.title }}</h2>
				</div>
			</div>
			<!-- //해더 -->
			<!-- 친구검색 -->
			<div class="serch_top">
				<div class="top top_wh">
					<div class="header_search">
						<input
							v-model="item_search.search_value"
							type="text" :placeholder="$language.common.search_friend"
							maxlength="20"
						>
					</div>
					<div class="header_search_btn">
						<div class="btn_del">
							<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
							<button
								v-if="item_search.search_value"
								class="active"
								@click="item_search.search_value = ''; getData()"
							><span class="hide">{{  $language.common.close }}</span></button>
						</div>
						<div class="util">
							<button
								@click="getData"
								class="img_button" type="submit" name="click"
							><span class="hide">{{  $language.common.search }}</span></button>
						</div>
					</div>
				</div>
			</div>
			<!-- //친구검색 -->
		</div>
		<!-- //고정해더 -->
		<!-- 컨텐츠 내용 -->
		<div class="section_wrap pt-120">
			<!-- 멤버초대 -->
			<div class="thumbnail_wrap">
				<div class="container">
					<div class="row">
						<ul class="thumbnail_list ct_invite">
							<h3 class="hide">{{  $language.common.search_friend_result }}</h3>
							<template
								v-if="items_member.length > 0"
							>
							<li
								v-for="(item, index) in invite_member"
								:key="'item_' + index"
							>
								<div class="ct_invite_list">
									<div class="circle_thumbnail_item">
										<div class="clear">
											<div class="picture">
												<img
													v-if="item.profle_nft_card_img_url"
													:src="$request.upload_url(item.profle_nft_card_img_url)"
												>
												<img
													v-else
													:src="require('@/assets/image/@profile_inner.png')" alt="No Image"
												/>
											</div>
											<div class="text_area">
												<strong>{{ item.nickname }}</strong>
												<p class="textzone textzone">
													{{  item.state_message }}
												</p>
											</div>
										</div>
										<div class="ct_invite_btn">

											<button
												@click="postAlarm(item)" class="mr-10"
											>
												<v-icon
													v-if="item.note_fg == 'Y'"
													style="color: #290397"
												>mdi-bell-ring-outline</v-icon>
												<v-icon
													v-else
												>mdi-bell-cancel-outline</v-icon>
											</button>
											<button
												@click="onConfirm(item)"
												class="btn_outline_blue btn_s"
												:disabled="item.is_invite"
											>{{  $language.common.cancel }}</button>
										</div>
									</div>
								</div>
							</li>
							</template>
							<template
								v-else
							>
							<li>
								<div class="list_none" style="overflow: hidden; margin-top: 0">
									<img :src="require('@/assets/image/list_none.png')" alt="이력없음">
									<span>{{  $language.common.no_searched_friend }}</span>
								</div>
							</li>
							</template>
						</ul>
					</div>
				</div>
			</div>
			<!-- //멤버초대 -->
		</div>

		<popup_confirm
			v-if="is_confirm"

			@click="postFriendCancel"
			@cancel="is_confirm = false"
		>
			<template
				v-slot:title
			>{{  $language.confirm.cancel_friend.title }}</template>
			<template
				v-slot:main-txt
			>{{  $language.confirm.cancel_friend.main }}</template>
			<template
				v-slot:sub-txt
			>{{  $language.confirm.cancel_friend.sub }}</template>
		</popup_confirm>
	</div>
</template>

<script>
import popup_confirm from "@/view/Layout/PopupConfirm";
export default {
	name: 'mafia102'
	,
	components: {popup_confirm},
	props: ['user']
	, data: function(){
		return {
			program: {
				name: this.$language.common.friend
				, title: this.$language.common.friend
				, type: 'cartel_sub'
				, not_header: true
				, not_footer: true
			}
			, items_member: []
			, item_cartel: {}
			, item_search: this.$storage.init({
				seach_type: ''
				, search_value: ''
			})
			, is_confirm: false
			, item_friend: {}
		}
	}
	, computed: {
		invite_member: function(){
			let self = this
			console.log(self.item_cartel.user)
			return this.items_member.filter(function(item_member){

				item_member.is_invite = false
				if(self.item_cartel.user){
					if(self.item_cartel.user.indexOf(item_member.id) > -1){
						item_member.is_invite = true
					}
				}
				return item_member
			})
		}
	}
	, methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_friends_list
					, data: {
						member_number: this.user.member_number
						, srchtext: this.item_search.search_value
						, page_number: this.$language.base.page_number
						, pagerecnum: this.$language.base.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_member = result.data.friend_list
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_search = true
				this.$bus.$emit('on', false)
			}
		}
		,getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'get'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						type: 'cartel'
						, key: 'id'
						, val: this.$route.params.idx
					}
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postInvite: async function(member_id){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_invite
					, data: {
						cartel_id: this.item_cartel.id
						, member_id: member_id
					}
					, name: 'postInvite'
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.succeedInvite})
					await this.getCartel()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postAlarm: async function(friend){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_friend_alarm
					, data: {
						member_number: this.user.member_number
						, friend_member_number: friend.friend_member_number
						, note_fg: friend.note_fg == 'Y' ? 'N' : 'Y'
					}
					, type: true
				})

				if(result.success){
					friend.note_fg = friend.note_fg == 'Y' ? 'N' : 'Y'
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postFriendCancel: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_friend_cancel
					, data: {
						member_number: this.user.member_number
						, friend_member_number: this.item_friend.friend_member_number
					}
					, type: true
				})

				console.log('postFriend result ', result)
				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}
		, onConfirm: function(item_friend){
			this.item_friend = item_friend
			this.is_confirm = true
		}
	}
	,async created() {
		this.$bus.$emit('onLoad', this.program)
		//await this.getCartel()
		await this.getData()
	}

}
</script>

<style>
</style>